import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3831525a&lang=pug&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/var/www/imoli.app/components/App/AppHeader/AppHeader.vue').default,PageServices: require('/var/www/imoli.app/components/Page/PageServices/PageServices.vue').default,PageStats: require('/var/www/imoli.app/components/Page/PageStats/PageStats.vue').default,ProjectsListItem: require('/var/www/imoli.app/components/Projects/ProjectsList/ProjectsListItem.vue').default,PageCustomers: require('/var/www/imoli.app/components/Page/PageCustomers/PageCustomers.vue').default,PageTestimonials: require('/var/www/imoli.app/components/Page/PageTestimonials/PageTestimonials.vue').default,AppFooter: require('/var/www/imoli.app/components/App/AppFooter/AppFooter.vue').default})
