

















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { joinURL, withQuery } from 'ufo'
import config from '@/config'

@Component<MProjectTeaserScroller>({})
export default class MProjectTeaserScroller extends Vue {
  baseUrl = config.cms.baseUrl

  @Prop({ default: '' }) image!: string
  @Prop({ default: null }) to!: string
  @Prop({ default: false }) scroll!: string
  @Prop({ default: null }) project!: any

  get imageUrl() {
    return withQuery(joinURL(this.baseUrl, 'assets', this.image), { format: 'webp', width: '700' })
  }
}
