import { render, staticRenderFns } from "./ProjectsListItem.vue?vue&type=template&id=64f7ef6c&lang=pug&"
import script from "./ProjectsListItem.vue?vue&type=script&lang=ts&"
export * from "./ProjectsListItem.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectsListItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/var/www/imoli.app/components/App/AppButton.vue').default,ProjectsListItemScroller: require('/var/www/imoli.app/components/Projects/ProjectsList/ProjectsListItemScroller.vue').default,ProjectsListItemDecoration: require('/var/www/imoli.app/components/Projects/ProjectsList/ProjectsListItemDecoration.vue').default})
