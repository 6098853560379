

























import { Component, Vue } from 'nuxt-property-decorator'

@Component<MServicesDecoration>({})
export default class MServicesDecoration extends Vue {
  scrollPosition = 0

  mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    }
  }

  onScroll() {
    if (process.client) {
      this.scrollPosition = window.scrollY
    }
  }
}
