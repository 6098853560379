






































































































import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<MServicesItem>({})
export default class MServicesItem extends Vue {
  @Prop({ default: undefined }) service!: {
    type: 'web-development' | 'e-commerce' | 'mobile-development' | 'branding';
    to: string;
    name: string;
    description: string;
  }

  get jsonld() {
    return {
      '@content': 'http://schema.org/',
      '@type': 'Service',
      'serviceType': this.$tc(this.service.name),
      'provider': {
        '@id': 'https://imoli.dev/#organization',
      },
      'areaServed': {
        '@type': 'Country',
        'name': 'PL'
      },
      'url': `https://imoli.dev/${this.$i18n.locale}${this.service.to}/#service`
    }
  }
}
