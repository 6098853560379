









import { Component, Vue } from 'nuxt-property-decorator'

@Component<OServices>({})
export default class OServices extends Vue {
  services = [
    { type: 'web-development', to: { name: 'services-web-development' }, name: 'services.web.name', description: 'services.web.description' },
    { type: 'mobile-development', to: { name: 'services-mobile-development' }, name: 'services.mobile.name', description: 'services.mobile.description' },
    { type: 'e-commerce', to: { name: 'software-e-commerce' }, name: 'services.ecommerce.name', description: 'services.ecommerce.description' },
    { type: 'branding', to: { name: 'services-branding' }, name: 'services.branding.name', description: 'services.branding.description' }
  ]
}
