



















import { Component, Vue } from "nuxt-property-decorator";
import headHelper from "@/helper/head";

@Component<HomePage>({
  head() {
    return headHelper(this.home[`meta_${this.$i18n.locale}`] || null);
  },
  computed: {
    project() {
      return this.home[`project_${this.$i18n.locale}`] || null;
    }
  },
  async asyncData(ctx) {
    return await ctx.$axios
      .get("items/home", { params: { fields: "*.*" } })
      .then(({ data: { data } }) => ({ home: data || null }))
      .catch(() => ({ home: null }));
  }
})
export default class HomePage extends Vue {
  home!: any;
}
